.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  
  .container p {
    font-size: 14px; /* Adjust as needed */
  }
  
  .container a {
    display: inline-block;
    margin: 0 8px;
  }
  
  .container svg {
    width: 24px; /* Adjust icon size as needed */
    height: 24px;
  }
  
  .container a:hover {
    color: #67C6FD; /* Adjust hover color as needed */
  }