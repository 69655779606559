.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Gap between grid items */
  padding: 20px;
  justify-content: center;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 15px; /* Adjust padding for smaller cards */
  text-align: center;
  transition: transform 0.3s ease;
  box-sizing: border-box;
  width: 220px; /* Fixed width for all cards */
  height: 100%; /* Ensure cards take full height */
  margin: 10px; /* Adjust margin for spacing */
  display: flex;
  flex-direction: column; /* Align items vertically */
}

.item {
  flex: 1; /* Expand item to fill available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space evenly between elements */
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Increase shadow on hover */
    transform: translateY(-5px); /* Move card up slightly on hover */
  }

.item img {
  width: 100%;
  height: auto;
  max-height: 150px; /* Limit maximum height for uniformity */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow around the image */
  margin-bottom: 20px; 
}

.item h4 {
  font-size: 0.875rem; /* text-sm */
  font-weight: bold; /* Make the text bold */
  line-height: 1.5; /* leading-6 */
  color: #333; /* text-gray-900 */
  margin-bottom: 8px; /* Add spacing between title and price */
}

.item p {
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  line-height: 1.5; /* leading-6 */
  color: #333; /* text-gray-900 */
  margin-bottom: 12px; /* Adjust spacing between price and button */
}

.total-price {
  font-weight: bold; /* Make the total price bold */
}

.buy-button {
  background-color: #5ab1e5;
  color: white;
  padding: 8px 16px; /* Adjust button padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  line-height: 1.5; /* leading-6 */
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #4a97c4;
}

.back-button-container {
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    left: 10px; /* Adjust left position as needed */
    z-index: 1; /* Ensure the back button appears above other elements */
  }